import { Helmet } from 'react-helmet-async';
import './UserNew.css';
// @mui
import { CircularProgress, Container, FormControl, InputLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

// hooks
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


import useResponsive from '../../hooks/useResponsive';

// components
import logo from '../../images/icons/logo.png';
import inviteUserImg from '../../images/image/invite-user.avif';
import { clearError, getUserDetails, updateUser } from '../../redux/actions/userAction';

import { UPDATE_USER_RESET } from '../../redux/constants/userConstant';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');


    // ----------------------------------------------------------------------
    const [role, setRole] = useState('')
    const [name, setName] = useState(null)
    const [active, setactive] = useState('')
    const [password, setPassword] = useState(null)
    const [websiteGAId, setWebsiteGAId] = useState(null)
    const [websiteName, setWebsiteName] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams()

    const { loading, isUpdated, error } = useSelector((state) => state.profile)
    const { loading: userLoading, user, error: userErr } = useSelector((state) => state.getUser)

    useEffect(() => {
        if (isUpdated) {
            toast.success('Updated successfully');
            navigate(-1);
            dispatch({ type: UPDATE_USER_RESET });
        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
        if (userErr) {
            toast.error(userErr);
            dispatch(clearError());
        }


    }, [isUpdated, error, navigate, dispatch])

    useEffect(() => {
        dispatch(getUserDetails(id))
    }, [userErr])

    useEffect(() => {

        setName(user?.name)
        setRole(user?.role)
        setactive(user?.isActive)
        setWebsiteGAId(user?.website?.websiteGAId)
        setWebsiteName(user?.website?.websiteName)

    }, [user])


    const updateSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(updateUser(id, { name, active, role,websiteGAId,websiteName }))
    }


    const handleChange = (event) => {
        setactive(event.target.value);
    };


    return (
        <>
            <Helmet>
                <title> Update User  | EOAI DASHBOARD </title>
            </Helmet>

            <StyledRoot className='bgbanner'>
                <div className='logoBox'>
                    {/* <img src={logo} width={30} alt="EOAI LOGO" /> */}
                </div>
                {mdUp && (
                    <StyledSection>
                        <img src={inviteUserImg} alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>

                        <Typography variant="h4" gutterBottom>
                            Update  / Edit
                            <Typography variant="h6" gutterBottom>
                                {user?.email}
                            </Typography>
                        </Typography>

                        <>
                            <form action="" onSubmit={updateSubmitHandler}>
                                <Stack spacing={2} paddingY={2}>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />

                                    <Stack spacing={1}>
                                        <InputLabel id="demo-controlled-open-select-label">Select Role</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={role}
                                            // label="Age"
                                            displayEmpty
                                            onChange={e => setRole(e.target.value)}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'super-admin'}>super-admin</MenuItem>
                                            <MenuItem value={'admin'}>admin</MenuItem>
                                        </Select>
                                    </Stack>

                                    <Stack spacing={1}>
                                        <InputLabel id="demo-controlled-open-select-label">User Active</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={active}
                                            // label="Age"
                                            displayEmpty
                                            onChange={handleChange}
                                            required
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'true'}>Yes</MenuItem>
                                            <MenuItem value={'false'}>No</MenuItem>
                                        </Select>
                                    </Stack>

                                        <TextField name="WebsiteName"
                                            onChange={e => setWebsiteName(e.target.value)}
                                            value={websiteName}
                                            required label="Website Name" />

                                        <TextField name="WebsiteGAId"
                                            onChange={e => setWebsiteGAId(e.target.value)}
                                            value={websiteGAId}
                                            required label="WebsiteGAId" />

                                    

                                    {/* <FormControl>
                                        <TextField name="password"
                                            onChange={e => setPassword(e.target.value)}
                                            required label="Password" />
                                    </FormControl><FormControl>
                                        <TextField name="ConfirmPassword"
                                            onChange={e => setConfirmPassword(e.target.value)}
                                            required label="Confirm Password" />
                                    </FormControl> */}
                                </Stack>





                                {loading ?

                                    (<LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>)
                                    :

                                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                                        UPDATE
                                    </LoadingButton>)

                                }
                            </form>
                        </>


                    </StyledContent>
                </Container>
            </StyledRoot >
        </>
    );
}
