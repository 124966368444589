import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPage';

 


import Page404 from './pages/Page404';


// users routes start
import DashboardAppPage from './pages/DashboardAppPage';
import AllUsersPage from './pages/Users/AllUsersPage';
import UserEdit from './pages/Users/UserEdit';
import UserNew from './pages/Users/UserNew';

import UsersEventsPage from './pages/analytics/UsersEventsPage';
import BrowserDevicePage from './pages/analytics/BrowserDevicePage';
import RetentionReportPage from './pages/analytics/RetentionReportPage';
import TrafficUrlPage from './pages/analytics/trafficUrlPage';
import SearchCountryPage from './pages/analytics/SearchCountryPage';

import ComparisonPage from './pages/analytics/ComparisonPage';


// ----------------------------------------------------------------------


export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardAppPage /> },
        // for admin  manage
        
        { path: 'user', element: <AllUsersPage /> },

        { path: 'users-events', element: <UsersEventsPage /> },
        { path: 'search-country', element: <SearchCountryPage /> },
        { path: 'page-wise-traffic', element: <TrafficUrlPage /> },
        { path: 'browser-device', element: <BrowserDevicePage /> },
        { path: 'retention-report', element: <RetentionReportPage /> },

        { path: 'comparison', element: <ComparisonPage /> },
        

      ],
    },
   

    {
      path: '/user',
      children: [
        { path: 'new', element: <UserNew /> },
        { path: 'edit/:id', element: <UserEdit /> },
      ],
    },
   
    {
      index: true,
      path: '/',
      element: <LoginPage />,
    },
   

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
