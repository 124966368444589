import { useState } from 'react'
import { useSelector } from 'react-redux'
import AllMonths from './AllMonths'
import ComparisonDataUi from './ComparisonDataUi'
import Skeleton from './Skleton'


const ComparisonPage = () => {
  const { user } = useSelector(state => state.users)

  const [allMonths, setAllMonths] = useState([])
  const [allData, setAllData] = useState([])
  const [fetchLoading, setFetchLoading] = useState(false)

  const data = {
    allMonths,
    setAllMonths,
    propertyId: user?.website?.websiteGAId,
    setAllData,
    allData, setFetchLoading,fetchLoading
  }

  console.log(allData)


  return (
    <div>


      <AllMonths data={data} />

      {
        fetchLoading ? <Skeleton/> :
          <>
            {
              allData.length > 0 && <ComparisonDataUi data={allData} />
            }
          </>
      }


      {/* <Button onClick={handleApiComparison} variant='outlined'>Find Comparison</Button> */}





    </div>
  )
}

export default ComparisonPage