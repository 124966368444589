import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, CircularProgress, Box, Button, Typography, FormControl, FormLabel, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';


import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
// components
import { clearError, loadUser, login } from '../../../redux/actions/userAction';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: '0.4rem',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function LoginForm( ) {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setemail] = useState(null)
  const [password, setpassword] = useState(null)

  const { loading, user, error } = useSelector((state) => state.users)


  useEffect(() => {
    if(error){
      toast.error(error)
      dispatch(clearError())
    }
  }, [error])
  

  const loginSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(login({email, password}))
  }






  return (
    <>
      <form action="" onSubmit={loginSubmitHandler}>
        <Stack spacing={3}>

          <FormControl>
            <TextField name="email"
              value={email}
              // disabled
              onChange={e => setemail(e.target.value)}
              required label="Email" />
          </FormControl>

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={e => setpassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <Checkbox name="remember"label="Remember me" />  */}
          {/* <Link variant="subtitle2" underline="hover" onClick={handleOpen}>
            Forgot password?
          </Link> */}
        </Stack>

        {loading ?

          (<LoadingButton fullWidth size="large" variant="text" >
            <CircularProgress color='success' />
          </LoadingButton>)
          :

          (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
            Login
          </LoadingButton>)

        }
      </form>



    </>
  );
}
