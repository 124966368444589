import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
import { comparisonApi } from './comparisionApi';

const AllMonths = ({ data }) => {
  const { allMonths,
    setAllMonths,
    propertyId, setAllData, allData, setFetchLoading, fetchLoading } = data

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleAdd = () => {

    if (startDate && endDate) {

      setAllMonths([...allMonths, { propertyId, startDate, endDate }]);
      handleApiComparison({ propertyId, startDate, endDate })
      setStartDate("")
      setEndDate("")
    } else {
      toast.warn("Please Select Both  Dates!")
    }
  }



  const handleDelete = (index) => {
    setAllMonths(allMonths.filter((_, i) => i !== index));
    setAllData(allData.filter((_, i) => i !== index));
  };



  const handleApiComparison = async (ele) => {
    setFetchLoading(true)
    const { data } = await comparisonApi(ele);
    const analytics = {
      date: ele,
      data
    }
    setAllData([...allData, analytics])
    setFetchLoading(false)
  }



  return (
    <div>
      <div className='md:p-5'>
        <h2 className='bg-slate-500 text-white p-2 rounded' >Months / Date wise Comparison</h2>
        <br />
        <p className='w-64 mb-4 bg-slate-200 text-slate-900 text-lg p-0 text-center rounded-full' >Please Select Date </p>

        <div className='flex gap-5'>
          <div>
            <p>Start Date</p>
            <input
              type="date"
              required
              className='bg-slate-300 px-5 rounded'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
          </div>
          <div>
            <p>End Date</p>
            <input
              type="date"
              required
              value={endDate}
              className='bg-slate-300 px-5 rounded'
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
          </div>
          <div className=''>
            {
              fetchLoading ? <p className='mt-6'>Please wait...</p> :
                <button onClick={handleAdd} className='bg-slate-600 mt-6   text-white px-8 rounded-full hover:bg-slate-400'>
                  {"Find"}
                </button>
            }
          </div>
        </div>

        <br />
        <ul className='flex gap-3'>
          {allData.map((item, index) => (
            <li key={index} className='bg-slate-200 px-4 py-1 rounded'>
              {item?.date?.startDate} to {item?.date?.endDate}{" "}
              <button  onClick={() => handleDelete(index)}><MdDelete color='red'/></button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AllMonths