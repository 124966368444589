import React from 'react';
import './skeleton.css'; // Import the custom CSS for the animation

const Skeleton = () => {
  return (
    <div className="space-y-4 md:p-10">
      <div className="h-10 bg-gray-200 rounded w-3/4 animate-pulse"> </div>
      <div className="h-10 bg-gray-200 rounded w-full animate-pulse"> </div>
      <div className="h-40 bg-gray-200 rounded w-5/6 animate-pulse"> </div>
    </div>
  );
};

export default Skeleton;
