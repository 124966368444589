// import downloadExcel from './downloadExcel.js'



const GAGraphData = ({ data }) => {
  console.log(data)
  const { users, search, event, country, pagesByUrl } = data[0]?.data


  return (
    <div className='w-full  flex flex-wrap p-2 md:p-5 '>

      {/* User */}
      <div className='w-full h-fit md:w-1/2  p-1'>

        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>

          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>Users</h5>
          </div>

          <div className=" flex justify-between py-4 overflow-auto">

            <div className='min-w-60'>
              <br />
              <h4 className='text-gray-500 text-md'>Total Users</h4>
              <h4 className='text-gray-500 text-md'>New Users</h4>
              <h4 className='text-gray-500 text-md'>User average session</h4>
            </div>


            {
              data?.map((item, index) => {
                const { users } = item?.data
                const { startDate, endDate } = item?.date
                const avgSession = Math.round(users[0]?.metricValues[2]?.value)

                return (
                  <div className='min-w-60 ml-2 text-center'>
                    <p className="bg-slate-200 rounded-full">{`${startDate} to ${endDate}`}</p>
                    <p>{users[0]?.metricValues[0]?.value}</p>
                    <p>{users[0]?.metricValues[1]?.value}</p>
                    <p>{`${Math.round(avgSession / 60)}m ${avgSession % 60}s`}</p>
                  </div>
                )
              })
            }


          </div>


        </div>
      </div>

      {/* Event Count */}
      <div className='w-full h-fit md:w-1/2  p-1 '>
        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>Event Count</h5>
          </div>

          <div className='w-full flex justify-between mb-2'>
            <h4 className='text-gray-500 font-bold text-md'>Event name</h4>
            <h4 className='text-gray-500 font-bold text-md'>Event count</h4>
          </div>

          <div className=" flex justify-between py-4 overflow-auto">

            <div className='min-w-60'>
              <br />
              <h4 className='text-gray-500 text-md'>Total Events</h4>
              <h4 className='text-gray-500 text-md'>Page Views</h4>
              <h4 className='text-gray-500 text-md'>Session Start</h4>


            </div>



            {
              data?.map((item, index) => {
                const { event } = item?.data
                const { startDate, endDate } = item?.date

                return (

                  <div className='min-w-60 ml-2 text-center'>
                    <p className="bg-slate-200 rounded-full">{`${startDate} to ${endDate}`}</p>
                    <p>{event[0]?.metricValues[0]?.value || 0}</p>
                    <p>{event[0]?.metricValues[1]?.value || 0}</p>
                    <p>{event[0]?.metricValues[2]?.value || 0}</p>
                  </div>
                )
              })
            }


          </div>


        </div>
      </div>

      {/* Country */}
      <div className='w-full h-fit md:w-1/2  p-1 '>
        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>Country - Users</h5>
          </div>

          <div className="flex overflow-auto gap-5 py-5">
            {
              data?.map(item => {
                const { country } = item.data
                const { startDate, endDate } = item?.date


                return (
                  <div className="min-w-80">
                    <p className="bg-slate-200 rounded-full text-center">{`${startDate} to ${endDate}`}</p>
                    <br />

                    <div className='w-full flex justify-between mb-2'>
                      <h4 className='text-gray-500 font-bold text-md'>Country</h4>
                      <h4 className='text-gray-500 font-bold text-md'>Users</h4>
                    </div>
                    {
                      country?.map((item, index) => (
                        <>
                          {
                            index < 10 &&
                            <div className='w-full flex justify-between ' key={index}>
                              <h4 className='text-gray-500 text-md'>{item?.dimensionValues[0]?.value}</h4>
                              <p>{item?.metricValues[0]?.value}</p>
                            </div>
                          }
                        </>
                      ))
                    }
                  </div>
                )
              })
            }
          </div>


        </div>
      </div>

      {/* Search */}

      <div className='w-full h-fit md:w-1/2  p-1 '>
        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>Search By</h5>
          </div>


          <div className="flex overflow-auto gap-5 py-5">
            {
              data?.map(item => {
                const { search } = item.data
                const { startDate, endDate } = item?.date


                return (
                  <div className="min-w-80">
                    <p className="bg-slate-200 rounded-full text-center">{`${startDate} to ${endDate}`}</p>
                    <br />

                    <div className='w-full flex justify-between mb-2'>
                      <h4 className='text-gray-500 font-bold text-md'>Medium</h4>
                      <h4 className='text-gray-500 font-bold text-md'>Sessions</h4>
                    </div>

                    {
                      search?.map((item, index) => (
                        <div className='w-full flex justify-between ' key={index}>
                          <h4 className='text-gray-500 text-md'>{item?.dimensionValues[0]?.value}</h4>
                          <p>{item?.metricValues[0]?.value}</p>
                        </div>
                      ))
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>


      {/* Url / Path Pages - views */}
      <div className='w-full h-fit md:w-1/2  p-1 '>
        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>Pages Title - Views</h5>
          </div>

          <div className="flex overflow-auto gap-5 py-5">
            {
              data?.map(item => {
                const { pagesByUrl } = item.data
                const { startDate, endDate } = item?.date


                return (
                  <div className="min-w-80">
                    <p className="bg-slate-200 rounded-full text-center">{`${startDate} to ${endDate}`}</p>
                    <br />

                    <div className='w-full flex justify-between mb-2'>
                      <h4 className='text-gray-500 font-bold text-md'>Page Url / Path</h4>
                      <h4 className='text-gray-500 font-bold text-md'>Views</h4>
                    </div>

                    {
                      pagesByUrl?.map((item, index) => (
                        <div className='w-full flex justify-between border-b-2 pb-2 mb-2 gap-20 ' key={index} >
                          <h4 className='text-gray-500 text-md '>{item?.dimensionValues[0]?.value}</h4>
                          <p>{item?.metricValues[0]?.value}</p>
                        </div>
                      ))
                    }
                  </div>
                )
              })
            }
          </div>

          {/* kj */}




        </div>
      </div>




    </div>
  )
}

export default GAGraphData