import React from 'react'
import { FaCloudDownloadAlt } from "react-icons/fa";
import { downloadExcel } from './downloadExcel'
// import downloadExcel from './downloadExcel.js'

import PieChart from './PieChart'
import EventPieChart from './EventPieChart'


const GAGraphData = ({data}) => {
    const { users,  event} = data
    console.log(data)

    const avgSession = Math.round(users[0]?.metricValues[2]?.value) || 0

    const downloadUser = () => {

        const data = [
            { Title: "Total Users", Value: users[0]?.metricValues[0]?.value, },
            { Title: "New Users", Value: users[0]?.metricValues[1]?.value },
            { Title: "User average session", Value: `${Math.round(avgSession / 60)}m ${avgSession % 60}s` }
        ];
        downloadExcel(data, 'users')
    }

    const downloadEventCount = () => {

        const data = [
            { Title: "Total Events", Value: event[0]?.metricValues[0]?.value, },
            { Title: "Page Views", Value: event[0]?.metricValues[1]?.value },
            { Title: "Session Start", Value: event[0]?.metricValues[2]?.value }
        ];
        downloadExcel(data, 'Event_Count')
    }

   

    return (
        <div className='w-full  flex flex-wrap p-2 md:p-20 '>

            {/* User */}
            <div className='w-full h-fit md:w-1/2  p-1 '>

                <div className='bg-white p-6 md:p-6 rounded shadow-lg'>

                    <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
                        <h5 className='text-gray-700 font-bold text-md '>Users</h5>
                        <button onClick={e => downloadUser()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>

                    </div>

                    <div className='w-full flex justify-between'>
                        <h4 className='text-gray-500 text-md'>Total Users</h4>
                        <p>{users[0]?.metricValues[0]?.value}</p>
                    </div>
                    <div className='w-full flex justify-between'>
                        <h4 className='text-gray-500 text-md'>New Users</h4>
                        <p>{users[0]?.metricValues[1]?.value}</p>
                    </div>
                    <div className='w-full flex justify-between'>
                        <h4 className='text-gray-500 text-md'>User average session</h4>
                        <p>{`${Math.round(avgSession / 60)}m ${avgSession % 60}s`}</p>
                    </div>

                    <PieChart data={users} isArray={false} />

                </div>
            </div>

            {/* Event Count */}
            <div className='w-full h-fit md:w-1/2  p-1 '>
                <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
                    <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
                        <h5 className='text-gray-700 font-bold text-md '>Event Count</h5>
                        <button onClick={e => downloadEventCount()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
                    </div>

                    <div className='w-full flex justify-between mb-2'>
                        <h4 className='text-gray-500 font-bold text-md'>Event name</h4>
                        <h4 className='text-gray-500 font-bold text-md'>Event count</h4>
                    </div>

                    <div className='w-full flex justify-between'>
                        <h4 className='text-gray-500 text-md'>Total Events</h4>
                        <p>{event[0]?.metricValues[0]?.value}</p>
                    </div>
                    <div className='w-full flex justify-between'>
                        <h4 className='text-gray-500 text-md'>Page Views</h4>
                        <p>{event[0]?.metricValues[1]?.value}</p>
                    </div>
                    <div className='w-full flex justify-between'>
                        <h4 className='text-gray-500 text-md'>Session Start</h4>
                        <p>{event[0]?.metricValues[2]?.value}</p>
                    </div>

                    <EventPieChart event={event} />

                </div>
            </div>



          



        </div>
    )
}

export default GAGraphData