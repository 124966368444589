import { FaCloudDownloadAlt } from "react-icons/fa";
import { downloadExcel } from './downloadExcel';
// import downloadExcel from './downloadExcel.js'

import BarChart from './BarChart';


const GAGraphData = ({ data }) => {
    const { pages, pagesByUrl } = data



    const downloadTitlePages = () => {

        const data = [];

        pages?.forEach(item => {
            data.push({
                "Page title and screen class": item?.dimensionValues[0]?.value,
                "Views": item?.metricValues[0]?.value,
            })

        });

        downloadExcel(data, 'Page_title_and_screen_class')
    }
    const downloadUrlPages = () => {

        const data = [];

        pagesByUrl?.forEach(item => {
            data.push({
                "Page Url/Path and screen class": item?.dimensionValues[0]?.value,
                "Views": item?.metricValues[0]?.value,
            })

        });

        downloadExcel(data, 'Page_url_path_and_views')
    }
 

    return (
        <div className='w-full  flex flex-wrap p-2 md:p-20 '>

            
           


            <div className='w-full h-fit md:w-1/2  p-1 '>
                <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
                    <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
                        <h5 className='text-gray-700 font-bold text-md '>Pages Title - Views</h5>
                        <button onClick={e => downloadUrlPages()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
                    </div>

                    <div className='w-full flex justify-between mb-2'>
                        <h4 className='text-gray-500 font-bold text-md'>Page Url / Path</h4>
                        <h4 className='text-gray-500 font-bold text-md'>Views</h4>
                    </div>
                    {
                        pagesByUrl?.map((item, index) => (
                            <div className='w-full flex justify-between border-b-2 pb-2 mb-2 gap-20 ' key={index} >
                                <h4 className='text-gray-500 text-md '>{item?.dimensionValues[0]?.value}</h4>
                                <p>{item?.metricValues[0]?.value}</p>
                            </div>
                        ))
                    }
                    <BarChart country={pagesByUrl} />


                </div>
            </div>




            <div className='w-full h-fit md:w-1/2  p-1 '>

                <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
                    <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
                        <h5 className='text-gray-700 font-bold text-md '>Pages Title - Views</h5>
                        <button onClick={downloadTitlePages} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>

                    </div>

                    <div className='w-full flex justify-between mb-2'>
                        <h4 className='text-gray-500 font-bold text-md'>Page title and screen class</h4>
                        <h4 className='text-gray-500 font-bold text-md'>Views</h4>
                    </div>
                    {
                        pages?.map((item, index) => (
                            <div className='w-full flex justify-between border-b-2 pb-2 mb-2 gap-20 ' key={index} >
                                <h4 className='text-gray-500 text-md '>{item?.dimensionValues[0]?.value}</h4>
                                <p>{item?.metricValues[0]?.value}</p>
                            </div>
                        ))
                    }


                </div>
            </div>




        </div>
    )
}

export default GAGraphData