import axios from "axios";
import {

    CLEAR_ERROR,
    GA_DATA_FAIL,
    GA_DATA_REQUEST,
    GA_DATA_SUCCESS,

} from '../constants/gaConstant'

import { host } from "../../utils/config";

export const getGaData = ({startDate,endDate,propertyId,type}) => async (dispatch) => {
    try {
        dispatch({ type: GA_DATA_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const { data } = await axios.get(`${host}/api/ga-data?propertyId=${propertyId}&startDate=${startDate}&endDate=${endDate}&type=${type}`, config);

        dispatch({ type: GA_DATA_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: GA_DATA_FAIL,
            payload: error?.response?.data.message,
        });
    }
};



export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

