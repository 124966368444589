import {
    CLEAR_ERROR,
    GA_DATA_SUCCESS,
    GA_DATA_FAIL,
    GA_DATA_REQUEST,
} from '../constants/gaConstant'




  export const getGA = (state = { data: {} }, action) => {
    switch (action.type) {
      case GA_DATA_REQUEST:
        return {
          loading: true,
        };
  
        case GA_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
          success:true
        };
  

        case GA_DATA_FAIL:
        return {
          ...state,
          loading: false,
          data: null,
          error: action.payload,
        };
      
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

