import React from 'react'
import { FaCloudDownloadAlt } from "react-icons/fa";
import { downloadExcel } from './downloadExcel'
import PieChart from './PieChart'
import BarChart from './BarChart'



const GAGraphData = ({ data }) => {
    const { search, country } = data



    const downloadSearchBy = () => {

        const data = [];

        search?.forEach(item => {
            data.push({
                "Medium": item?.dimensionValues[0]?.value,
                "Sessions": item?.metricValues[0]?.value,
            })

        });

        downloadExcel(data, 'SearchBy')
    }

    
    
    const downloadCountry = () => {

        const data = [];

        country?.forEach(item => {
            data.push({
                "Page Url/Path and screen class": item?.dimensionValues[0]?.value,
                "Views": item?.metricValues[0]?.value,
            })

        });

        downloadExcel(data, 'country')
    }

    return (
        <div className='w-full  flex flex-wrap p-2 md:p-20 '>


            {/* Country */}
            <div className='w-full h-fit md:w-1/2  p-1 '>
                <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
                    <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
                        <h5 className='text-gray-700 font-bold text-md '>Country - Users</h5>
                        <button onClick={e => downloadCountry()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
                    </div>

                    <div className='w-full flex justify-between mb-2'>
                        <h4 className='text-gray-500 font-bold text-md'>Country</h4>
                        <h4 className='text-gray-500 font-bold text-md'>Users</h4>
                    </div>
                    {
                        country?.map((item, index) => (
                            <div className='w-full flex justify-between ' key={index}>
                                <h4 className='text-gray-500 text-md'>{item?.dimensionValues[0]?.value}</h4>
                                <p>{item?.metricValues[0]?.value}</p>
                            </div>
                        ))
                    }

                    <BarChart country={country} />

                </div>
            </div>

          
            <div className='w-full h-fit md:w-1/2  p-1 '>
                <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
                    <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
                        <h5 className='text-gray-700 font-bold text-md '>Search By</h5>
                        <button onClick={e => downloadSearchBy()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
                    </div>
                    <div className='w-full flex justify-between mb-2'>
                        <h4 className='text-gray-500 font-bold text-md'>Medium</h4>
                        <h4 className='text-gray-500 font-bold text-md'>Sessions</h4>
                    </div>


                    {
                        search?.map((item, index) => (
                            <div className='w-full flex justify-between ' key={index}>
                                <h4 className='text-gray-500 text-md'>{item?.dimensionValues[0]?.value}</h4>
                                <p>{item?.metricValues[0]?.value}</p>
                            </div>
                        ))
                    }

                    <PieChart data={search} isArray={'true'} />

                </div>
            </div>







        </div>
    )
}

export default GAGraphData