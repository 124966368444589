import React, { useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Button, CircularProgress, TextField, InputAdornment } from '@mui/material';
import Modal from '@mui/material/Modal';
// mocks_
import { FaWhatsapp } from 'react-icons/fa';


import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import './help.css'

// ----------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import userProfile from '../../../images/icons/user1.jpg'

import account from '../../../_mock/account';
import { LOGIN_RESET, UPDATE_USER_RESET } from '../../../redux/constants/userConstant';
// import { CREATE_QUERY_RESET } from '../../../redux/constants/contactConstant';
import { clearError, logout, updatePassword, updateProfile } from '../../../redux/actions/userAction';
import { getDate } from '../../../utils/getDate';
// import { queryAction } from '../../../redux/actions/contactAction';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    navigate: '/'
  },

  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  borderRadius: '0.4rem',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const profileStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  borderRadius: '0.4rem',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 5,
}
const helpStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  borderRadius: '0.4rem',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 5,
}
export default function AccountPopover() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // get user

  const { loading, isAuthenticated, success, user, error } = useSelector((state) => state.users)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(totalUsers, resultPerPage, users)

  useEffect(() => {
    // dispatch(getAllUsers(page))
    if (error) {
      toast.error(error);
      dispatch(clearError())
    }
    if(!success){
      dispatch({type:LOGIN_RESET})
    }
  }, [error, isAuthenticated, !success])

  if (loading === false) {
    if (isAuthenticated === false) {
      toast.success('Logout');
      navigate('/')
    }
    
  }
  const logOutHandle = () => {
    // alert('l')
    dispatch(logout())

    
  }
  

  // modal box 
  const [modalOpen, setModalOpen] = useState(false);

  const ModalHandleOpen = () => {
    setModalOpen(true)
    handleClose(false)
  };
  const ModalHandleClose = () => {
    setModalOpen(false)
    // setChangePasswordModalOpen(false) 

  }


  // edit profile modal
  const { loading: loadingUserUpdate, isUpdated, error: errorUserUpdate } = useSelector((state) => state.profile)

  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const [phone, setPhone] = useState()

  const [EditModalOpen, setEditModalOpen] = useState(false);

  const EditModalHandleOpen = () => {
    setEditModalOpen(true)
    ModalHandleClose(false)
  };
  const EditHandleClose = () => {
    setEditModalOpen(false);
    ModalHandleOpen()
  }

  useEffect(() => {
    if (errorUserUpdate) {
      toast.error(errorUserUpdate)
      dispatch(clearError())
    }
    if (isUpdated) {
      toast.success('Updated');
      dispatch({ type: UPDATE_USER_RESET });
      EditHandleClose();
    }

    setName(user?.name)
    setEmail(user?.email)
    setPhone(user?.phone)

  }, [errorUserUpdate, isUpdated])

  const editProfileSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(updateProfile({ email, name, phone }))
  }

  // Change password modal
  const [showPassword, setShowPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const changePasswordModalHandleOpen = () => {
    setChangePasswordModalOpen(true)
    ModalHandleClose(false)
  };
  const changePasswordHandleClose = () => {
    setChangePasswordModalOpen(false);
    ModalHandleOpen()
  }

  const changePasswordSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(updatePassword({ oldPassword, 'newPassword': password, confirmPassword }))
  }
  const date = new Date(user?.createAt)

  // help desk Modal --------------------------------------------- 

  return (
    <>
      {/* <Button onClick={handleOpenHelp} >Help Desk</Button>  */}
      {/* <a target="_blank" rel="noreferrer" href='https://wa.me/9717522958' style={{paddingTop:'0.4vmax'}} ><FaWhatsapp color='green' size={24}/></a>  */}

      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <Avatar src={account.photoURL} alt="photoURL" />  */}
        <img src={userProfile} alt="" width={35} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <Link className='aTag' to={option.navigate}>
              <MenuItem key={option.label} onClick={handleClose}>
                {option.label}
              </MenuItem>
            </Link>
          ))}
          {/* <MenuItem key={'profile'} onClick={ModalHandleOpen}>
            Profile
          </MenuItem> */}
          {/* <MenuItem key={'my-organization'}>
            <Link to={'my-organization'} style={{ textDecoration: 'none' }}>
              My Organization
            </Link>
          </MenuItem> */}

          {/* {
            user?.role !== 'admin' && 
            <MenuItem key={'renew-or-certificate'}>
            <Link to={'renew-or-certificate'} style={{ textDecoration: 'none' }}>
              My Membership
            </Link>
          </MenuItem> 
          }
          */}


        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={e => { handleClose(); logOutHandle() }} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>

      {/* profile modal code  */}
      <div>
        <Modal
          open={modalOpen}
          onClose={ModalHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={profileStyle}>
            <Stack spacing={6} direction='row' justifyContent={'center'} marginBottom='0.8vmax '>

              <Box sx={{ width: '45%', padding: '3vmax' }}>

                <img src="https://www.svgrepo.com/show/255158/profile-user.svg" width={'100%'} alt="profile" />

              </Box>

              <Box sx={{ width: '55%' }} >
                <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
                  <Typography id="modal-modal-title" variant="h5" component="h2">
                    Profile
                  </Typography>
                  <Button onClick={ModalHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>

                </Stack>

                <Divider />

                <Stack spacing={3} margin='0.5vmax 0' marginTop='0.7vmax'>
                  <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                    <b>Name</b> : {user?.name}
                  </Typography>
                </Stack>
                <Stack spacing={3} margin='0.5vmax 0'>
                  <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                    <b>Email</b> : {user?.email}
                  </Typography>
                </Stack>
                {
                  user?.phone &&
                  <Stack spacing={3} margin='0.5vmax 0'>
                    <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                      <b>Phone</b> : {user?.phone}
                    </Typography>
                  </Stack>
                }

                <Stack spacing={3} margin='0.5vmax 0'>
                  <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                    <b>Verified</b> : {user?.verified === 'verified' ? 'Yes' : 'No'}
                  </Typography>
                </Stack>
                <Stack spacing={3} margin='0.5vmax 0'>
                  <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                    <b>Role</b> : {user?.role}
                  </Typography>
                </Stack>
                <Stack spacing={3} margin='0.5vmax 0'>
                  <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                    <b>Membership Status</b> : {user?.memberShipStatus === 1 ? '1 year' : 'LifeTime'}
                  </Typography>
                </Stack>
                <Stack spacing={3} margin='0.5vmax 0'>
                  <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                    <b>Membership Expired</b> : {getDate(user?.memberShipExpired)}
                  </Typography>
                </Stack>
                <Stack spacing={3} margin='0.2vmax 0'>
                  <Typography id="modal-modal-title" fontSize={'1vmax'} color={'gray'} component="h2">
                    <b>Join dated</b> : {date.toString().split('G')[0]}
                  </Typography>
                </Stack>

                <Stack spacing={1} flex={'flex'} marginTop='1.5vmax' width={'40%'}>
                  <Button variant="outlined" onClick={EditModalHandleOpen}>Edit Profile</Button>
                  <Button variant="outlined" onClick={changePasswordModalHandleOpen}>Change Password</Button>

                </Stack>
              </Box>
            </Stack>
          </Box>



        </Modal>
      </div>


      {/* Edit profile modal code  */}
      <div>
        <Modal
          open={EditModalOpen}
          // onClose={EditModalHandleOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} >
            <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Edit Profile
              </Typography>
              <Button onClick={EditHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>

            </Stack>

            <Divider />

            <form action="" onSubmit={editProfileSubmitHandler}>
              <Stack spacing={3} margin='1.5vmax 0'>
                <TextField name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required label="Name" />
              </Stack>
              <Stack spacing={3} margin='1.5vmax 0'>
                <TextField name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required label="Email address" />
              </Stack>
              <Stack spacing={3} margin='1.5vmax 0'>
                <TextField name="phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  required label="Phone" />
              </Stack>

              {loadingUserUpdate ?

                (<LoadingButton fullWidth size="large" variant="text" >
                  <CircularProgress color='success' />
                </LoadingButton>)
                :

                (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                  Update
                </LoadingButton>)

              }
            </form>
          </Box>
        </Modal>
      </div>

      {/* Change Password modal code  */}
      <div>
        <Modal
          open={changePasswordModalOpen}
          // onClose={changePasswordHandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} >
            <Stack spacing={3} direction='row' justifyContent={'space-between'} marginBottom='0.8vmax '>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Change Password
              </Typography>
              <Button onClick={changePasswordHandleClose} style={{ color: 'gray' }} variant="text" >close</Button>
            </Stack>

            {/* <Divider /> */}

            <form action="" style={{ marginTop: '1vmax' }} onSubmit={changePasswordSubmitHandler}>
              <Stack spacing={3} margin='1vmax 0'  >
                <TextField
                  name="oldpassword"
                  label="Old Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => setOldPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Stack spacing={3} margin='1vmax 0'  >
                <TextField
                  name="password"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Stack spacing={3} margin='1vmax 0'  >
                <TextField
                  name="confirmpassword"
                  label="Confirm Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={e => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>


              {loadingUserUpdate ?

                (<LoadingButton fullWidth size="large" variant="text" >
                  <CircularProgress color='success' />
                </LoadingButton>)
                :

                (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                  Update
                </LoadingButton>)

              }
            </form>
          </Box>
        </Modal>
      </div >


      {/* <div className='helpBox' >

                <img src={require('../../images/icons/help.png')} alt="Help" />

            </div> */}

      {/* Modal */}


    </>
  );
}
