import React, { useEffect, useState } from 'react'

const SelectDate = () => {
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [save, setSave] = useState('Save')


    useEffect(() => {
        const startDate = localStorage.getItem('startDate')
        const endDate = localStorage.getItem('endDate')

        setStartDate(startDate)
        setEndDate(endDate)
    }, [])

    const dateSaveHandle = () => {
        localStorage.setItem('startDate', startDate.toString());
        localStorage.setItem('endDate', endDate.toString());
        setSave('Saved')
    }

    setTimeout(() => {
        setSave('Save')
    }, 5000);

    return (
        <div className='flex space-x-4 justify-center align-middle'>
            <div className=''>
                <p className='text-gray-600'>Start date</p>
                <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} name="" id="" placeholder='Start Date' className='border text-white px-4 rounded-full bg-slate-300' />
            </div>
            <div>
                <p className='text-gray-600'>End date</p>
                <input type="date" name="" value={endDate} onChange={e => setEndDate(e.target.value)} id="" placeholder='End Date' className='border text-white px-4 rounded-full bg-slate-300' />
            </div>

            <div className=' pt-6'>
                <button className='w-14  h-7  text-center  rounded-full bg-slate-600 hover:shadow-lg hover:bg-slate-400 ' onClick={dateSaveHandle}>{save}</button>
            </div>
        </div>
    )
}

export default SelectDate