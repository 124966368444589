import { Helmet } from 'react-helmet-async';
import './UserNew.css'
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Divider, Stack, Button, IconButton, InputAdornment, TextField, CircularProgress, Box, MenuItem, Select, OutlinedInput, useTheme, InputLabel, FormControl } from '@mui/material';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';


import { IoIosArrowBack } from 'react-icons/io';
import useResponsive from '../../hooks/useResponsive';

// components
import { clearError, register } from '../../redux/actions/userAction';
import logo from '../../images/icons/logo.png'
import inviteUserImg from '../../images/image/invite-user.avif'

import { CREATE_USER_RESET } from '../../redux/constants/userConstant';
// import { setDate } from 'date-fns';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
    const mdUp = useResponsive('up', 'md');


    // ----------------------------------------------------------------------

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState(null)
    const [role, setRole] = useState('')
    const [name, setName] = useState(null)
    const [password, setPassword] = useState(null)
    const [websiteGAId, setWebsiteGAId] = useState(null)
    const [websiteName, setWebsiteName] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)

    const { loading, success, error } = useSelector((state) => state.createUser)

    useEffect(() => {
        if (success) {
            toast.success('User Created and Invite Send successfully.');
            navigate(-1);
            dispatch({ type: CREATE_USER_RESET });
        }
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }
    }, [success, error, navigate, dispatch])


    const inviteUserSubmitHandler = (e) => {
        e.preventDefault()
        if(password !== confirmPassword ){
            toast.error('Password and confirm Password not matched.')
            return 
        }
        dispatch(register({ email, role, name, websiteGAId, websiteName, password }))
    }
    const roles = [
        'admin',
        'super-admin',
    ]

    const handleChange = (event) => {
        setRole(event.target.value);
    };


    return (
        <>
            <Helmet>
                <title> Invite New User  | EOAI DASHBOARD </title>
            </Helmet>

            <StyledRoot className='bgbanner'>
                <div className='logoBox'>
                    <Link to={-1} className='BackBtn'><IoIosArrowBack /></Link>
                </div>
                {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{ px: 5, mt: 1, mb: 5, textAlign: 'center', display: 'flex', gap: '2rem', alignItems: 'center' }}>
                            {/* <img src={logo} width={40} alt="EOAI LOGO" /> */}
                            Welcome New User
                        </Typography>
                        <img src={inviteUserImg} alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                            Invite New User to GA Dashboard
                        </Typography>

                        {/* new user Form  */}
                        <>
                            <form action="" onSubmit={inviteUserSubmitHandler}>
                                <Stack spacing={3} paddingY={2}>

                                    <FormControl>
                                        <TextField name="name"
                                            onChange={e => setName(e.target.value)}
                                            required label="Name" />
                                    </FormControl>
                                    <TextField name="email"
                                        onChange={e => setEmail(e.target.value)}
                                        required label="Email address" />
                                    <FormControl>
                                        <InputLabel id="demo-controlled-open-select">Select Role</InputLabel>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            value={role}
                                            label="Role      space"
                                            placeholder='None'
                                            onChange={handleChange}
                                        >
                                            <MenuItem
                                                key={role}
                                                value={''}
                                                selected
                                                color='success'
                                            >
                                                <em>None</em>
                                            </MenuItem>
                                            {roles.map((role) => (
                                                <MenuItem
                                                    key={role}
                                                    value={role}
                                                >
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                    <FormControl>
                                        <TextField name="WebsiteGAId"
                                            onChange={e => setWebsiteGAId(e.target.value)}
                                            required label="WebsiteGAId" />
                                    </FormControl>

                                    <FormControl>
                                        <TextField name="WebsiteName"
                                            onChange={e => setWebsiteName(e.target.value)}
                                            required label="Website Name" />
                                    </FormControl>

                                    <FormControl>
                                        <TextField name="password"
                                            onChange={e => setPassword(e.target.value)}
                                            required label="Password" />
                                    </FormControl><FormControl>
                                        <TextField name="ConfirmPassword"
                                            onChange={e => setConfirmPassword(e.target.value)}
                                            required label="Confirm Password" />
                                    </FormControl>


                                </Stack>



                                {loading ?

                                    (<LoadingButton fullWidth size="large" variant="text" >
                                        <CircularProgress color='success' />
                                    </LoadingButton>)
                                    :

                                    (<LoadingButton fullWidth size="large" type="submit" variant="contained" >
                                        Create User
                                    </LoadingButton>)

                                }
                            </form>
                        </>


                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
