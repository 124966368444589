import { FaCloudDownloadAlt } from "react-icons/fa";
import { downloadExcel } from './downloadExcel';
// import downloadExcel from './downloadExcel.js'

import BarChart from './BarChart';


const GAGraphData = ({ data }) => {
    const { userRetention } = data

    const downloadUserRetention = () => {

        const data = [];

        userRetention?.forEach(item => {
            data.push({
                "Type": item?.dimensionValues[0]?.value,
                "Users": item?.metricValues[0]?.value,
            })

        });

        downloadExcel(data, 'User_retention')
    }
   
 

    return (
        <div className='w-full  flex flex-wrap p-2 md:p-20 '>

            
           


            <div className='w-full h-fit md:w-1/2  p-1 '>
                <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
                    <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
                        <h5 className='text-gray-700 font-bold text-md '>User Retention</h5>
                        <button onClick={e => downloadUserRetention()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
                    </div>

                    <div className='w-full flex justify-between mb-2'>
                        <h4 className='text-gray-500 font-bold text-md'>Type</h4>
                        <h4 className='text-gray-500 font-bold text-md'>Users</h4>
                    </div>
                    {
                        userRetention?.map((item, index) => (
                            <div className='w-full flex justify-between border-b-2 pb-2 mb-2 gap-20 ' key={index} >
                                <h4 className='text-gray-500 text-md '>{item?.dimensionValues[0]?.value}</h4>
                                <p>{item?.metricValues[0]?.value}</p>
                            </div>
                        ))
                    }
                    <BarChart country={userRetention} />


                </div>
            </div>







        </div>
    )
}

export default GAGraphData