import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import Skeleton from './Skleton'

// import BarChart from './BarChart'


import UEUi from './TrafficPageUi'

import { clearError, getGaData } from '../../redux/actions/gaAction'

const type = 'p-pu';

const UsersEventsPage = () => {

  const { user } = useSelector(state => state.users)
  const { loading, data, success, error } = useSelector(state => state.getGA)

  const dispatch = useDispatch()

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [websiteId, setWebsiteId] = useState('')

  const [errorText, setError] = useState('')

  

  useEffect(() => {
    const startDate = localStorage.getItem('startDate')
    const endDate = localStorage.getItem('endDate')

    setWebsiteId(user?.website?.websiteGAId)
    setStartDate(startDate)
    setEndDate(endDate)
  }, [user])


  


  useEffect(() => {
    if (error) {
      setError(error)
      toast.error(error)
      dispatch(clearError())
    }

  }, [error])


  console.log(data)

  const reLoadHandle = () => {
    const startDate = localStorage.getItem('startDate')
    const endDate = localStorage.getItem('endDate')

    setStartDate(startDate)
    setEndDate(endDate)

    if (startDate && endDate && websiteId) dispatch(getGaData({ startDate, endDate, propertyId: websiteId, type }))

  }

  useEffect(() => {

    reLoadHandle()

  }, [startDate, endDate , user])


  return (
    <div>
      {/* User */}
      {
        // `(${startDate}) - (${endDate})`
      }
      <div className='w-full text-right pr-12'>
        <Button variant='contained' onClick={reLoadHandle}>Reload</Button>
      </div>
      {
        !loading ?
          <>
           {
              !success &&
              <div>
                {errorText &&
                  <p className='text-red-500 bg-red-100 p-10 rounded mt-4'>
                    {`Error  : ${errorText}`}
                  </p>
                }

                {
                 ( !startDate && !endDate ) && 
                 <div className='text-yellow-500 bg-yellow-50 text-center p-10 mt-4 text-lg font-bold'>
                    Please Select Start Date and End Date
                 </div>
                }
              </div>
            }
            
            {
              (data?.pages && success) && <UEUi data={data} />
            }
          </>
          : <Skeleton />
      }
    </div>
  )
}

export default UsersEventsPage