import axios from "axios";
import { host } from "../../utils/config";

export const comparisonApi = async ({startDate,endDate,propertyId}) => {
    try {
        console.log(startDate, endDate ,propertyId)
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
        const { data } = await axios.get(`${host}/api/ga-comparison?propertyId=${propertyId}&startDate=${startDate}&endDate=${endDate}`, config);
        // console.log(data)
        return data 

    } catch (error) {
        console.log(error)
        return error
    }
};