import React from 'react'
import { FaCloudDownloadAlt } from "react-icons/fa";
import { downloadExcel } from './downloadExcel'
import PieChart from './PieChart'
import BarChart from './BarChart'



const GAGraphData = ({ data }) => {
  const { browser, device } = data
  const { category, os } = device


  const downloadBrowserBy = () => {

      const data = [];

      browser?.forEach(item => {
          data.push({
              "Browser": item?.dimensionValues[0]?.value,
              "Users": item?.metricValues[0]?.value,
          })

      });

      downloadExcel(data, 'Browser')
  }



  const downloadCategory = () => {

      const data = [];

      category?.forEach(item => {
          data.push({
              "Category" : item?.dimensionValues[0]?.value,
              "Users": item?.metricValues[0]?.value,
          })

      });

      downloadExcel(data, 'Category')
  }

  
  const downloadOs = () => {

    const data = [];

    os?.forEach(item => {
        data.push({
            "OS" : item?.dimensionValues[0]?.value,
            "Users": item?.metricValues[0]?.value,
        })

    });

    downloadExcel(data, 'OS')
}

  return (
    <div className='w-full  flex flex-wrap p-2 md:p-20 '>


      {/* Browser */}
      <div className='w-full h-fit md:w-1/2  p-1 '>
        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>Browsers - Users</h5>
            <button onClick={e => downloadBrowserBy()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
          </div>

          <div className='w-full flex justify-between mb-2'>
            <h4 className='text-gray-500 font-bold text-md'>Browser</h4>
            <h4 className='text-gray-500 font-bold text-md'>Users</h4>
          </div>
          {
            browser?.map((item, index) => (
              <div className='w-full flex justify-between ' key={index}>
                <h4 className='text-gray-500 text-md'>{item?.dimensionValues[0]?.value}</h4>
                <p>{item?.metricValues[0]?.value}</p>
              </div>
            ))
          }

          <BarChart country={browser} />

        </div>
      </div>

      {/* OS */}
      <div className='w-full h-fit md:w-1/2  p-1 '>
        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>OS - Users</h5>
            <button onClick={e => downloadOs()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
          </div>

          <div className='w-full flex justify-between mb-2'>
            <h4 className='text-gray-500 font-bold text-md'>OS</h4>
            <h4 className='text-gray-500 font-bold text-md'>Users</h4>
          </div>
          {
            os?.map((item, index) => (
              <div className='w-full flex justify-between ' key={index}>
                <h4 className='text-gray-500 text-md'>{item?.dimensionValues[0]?.value}</h4>
                <p>{item?.metricValues[0]?.value}</p>
              </div>
            ))
          }

          <BarChart country={os} />

        </div>
      </div>

       {/* Category */}
       <div className='w-full h-fit md:w-1/2  p-1 '>
        <div className='bg-white p-6 md:p-6 rounded shadow-lg'>
          <div className='w-full flex justify-between mb-2 bg-slate-100 mb-2  p-2 rounded'>
            <h5 className='text-gray-700 font-bold text-md '>Category - Users</h5>
            <button onClick={e => downloadCategory()} className='bg-slate-700 px-2 py-1 rounded hover:bg-slate-400' ><FaCloudDownloadAlt size={20} color='white' /></button>
          </div>

          <div className='w-full flex justify-between mb-2'>
            <h4 className='text-gray-500 font-bold text-md'>Category</h4>
            <h4 className='text-gray-500 font-bold text-md'>Users</h4>
          </div>
          {
            category?.map((item, index) => (
              <div className='w-full flex justify-between ' key={index}>
                <h4 className='text-gray-500 text-md'>{item?.dimensionValues[0]?.value}</h4>
                <p>{item?.metricValues[0]?.value}</p>
              </div>
            ))
          }

          <BarChart country={category} />

        </div>
      </div>


    </div>
  )
}

export default GAGraphData