import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { allUserReducers, allUsersReducers, createUserReducer, enrolledUsers, forgotPasswordReducers, profiletReducers, userDetailsReducers, usertReducers } from './reducers/userReducer';
import { getGA } from './reducers/gaReducer';



const reducer = combineReducers({
    profile: profiletReducers,
    users: usertReducers,
    allUsers: allUsersReducers,
    forgetPassword: forgotPasswordReducers,
    createUser: createUserReducer,
    getUser: userDetailsReducers,
    allUsersForExc: allUserReducers,
    enrolledUsers,
    // -----------------
    getGA
    
});

const intitialeState = {

};

const middleware = [thunk]

const store = createStore(
    reducer,
    intitialeState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;
