import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    //   { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const CustomPieChart = ({ data: search, isArray }) => {

    const data = [];

    if (isArray) {
        search?.forEach(item => {
            data.push({
                "name": item?.dimensionValues[0]?.value,
                "value": Number(item?.metricValues[0]?.value),
            })
        });
    }
    else {
        data.push(
            { 'name': "Total Users", 'value': Number(search[0]?.metricValues[0]?.value), },
        )
        data.push(
            { 'name': "New Users", 'value': Number(search[0]?.metricValues[1]?.value) },
        )

    }
    return (
        <PieChart width={400} height={400}>
            <Pie
                data={data}
                cx={200}
                cy={200}
                innerRadius={60}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Tooltip />
            <Legend />
        </PieChart>
    );
};

export default CustomPieChart;
