import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
 
export const downloadExcel = (data, fileName = 'ga-data') => {
    // Check if data is an array, if not convert it to an array
    const dataArray = Array.isArray(data) ? data : [data];

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dataArray);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, `${fileName}.xlsx`);
};

