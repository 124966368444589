import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const sampleData = [
    { name: "John Doe", age: 30, city: "New York" },
    { name: "Jane Smith", age: 25, city: "Los Angeles" },
    { name: "Alice Johnson", age: 35, city: "Chicago" },
    { name: "Bob Brown", age: 28, city: "Houston" },
    { name: "John Doe", age: 30, city: "New York" },
    { name: "Jane Smith", age: 25, city: "Los Angeles" },
    { name: "Alice Johnson", age: 35, city: "Chicago" },
    { name: "Bob Brown", age: 28, city: "Houston" },
    { name: "John Doe", age: 30, city: "New York" },
    { name: "Jane Smith", age: 25, city: "Los Angeles" },
    { name: "Alice Johnson", age: 35, city: "Chicago" },
    { name: "Bob Brown", age: 28, city: "Houston" },
];

const BarChartComponent = ({ country }) => {
    const data = []
    country?.forEach((item,i) => {
        
        if(i<10)
        data.push({
            "name": item?.dimensionValues[0]?.value,
            "value": Number(item?.metricValues[0]?.value),
        })
    });
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                width={'100%'}
                height={300}
                data={data}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartComponent;
