
// export {localX as default}
// export {localX as exportedX};

const port = window.location.hostname
// console.log(port)

if (port === '62.72.31.157') {
    exports.host = "http:// 62.72.31.157:5120"
}
else if (port === 'localhost') {
    exports.host = "http://localhost:5120";
}
else if (port === 'analytics.designavenue.co.in') {
    exports.host = "https://analytics.designavenue.co.in"
}
else {
    exports.host = "https://analytics.designavenue.co.in"
}






//  host = "http://157.245.99.110:5409"
//   host = "http://portal.eoais.com"
//  host = "http://localhost:5409";

// export default host
// console.log(this.host)

